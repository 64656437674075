import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
// Import Shared Components
import { ButtonGroup, Loader, Details } from 'shared-components';
import { isHRB } from 'shared-components/src/utils/whitelabel';
// Import Shared Utils
import { formatExpiryDate } from 'shared-components/src/utils/formatDateTime';
import { get, post } from 'shared-components/src/utils/http';
// Import translations
import { profileEn } from '../../../../i18n/profile';
// Import Global Components
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import Alert from '../../../../components/Alert';
import Text from '../../../../components/Text';
// Import Global Hook
import useAlert from '../../../../hooks/useAlert';
// Import Local Components
import GetPinModal from '../GetPinModal';
import GetGalileoPinModal from '../GetGalileoPinModal';
import SetPinModal from '../SetPinModal';
// Import Local styles
import { CardInfoGroup, TitleContainer, LoaderContainer } from './styles';

const CardInfo = ({ currentAccount, programProcessor, programConfigs }) => {
  const intl = useIntl();
  // modal states
  const [showGetPinModal, setShowGetPinModal] = useState(false);
  const [showSetPinModal, setShowSetPinModal] = useState(false);
  // component states
  const [pinModalAlert, setPinModalAlert] = useAlert();
  const [pin, setPin] = useState(null);
  const [cardInfo, setCardInfo] = useState({
    cardNumber: '--',
    expiryDate: '--',
    cvv: '--',
  });
  const [cardInfoIsLoading, setCardInfoIsLoading] = useState(true);
  const [showCardInfoErrorAlert, setShowCardInfoErrorAlert] = useAlert();
  const [account, setAccount] = useState(null);
  // GetPinModal is unmounted & remounted whenever an error occurs
  // to ensure useEffect within GetPinModal only run onces, use initialPinFetch
  const initialPinFetch = useRef(true);

  // On initial render, fetch info for cardholder
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Cardholder
        const cardholderData = await get(
          '/api/v1/cardholder_portal/cardholder'
        );
        // Find the primary cardholder account
        const primaryAccount = cardholderData.data.accounts.find(
          acc =>
            acc.processor_reference ===
            cardholderData.data.primary_processor_reference
        );
        setAccount(primaryAccount);
        setCardInfo(prev => ({
          ...prev,
          cardNumber: primaryAccount.cards[0].number || '--',
        }));
      } catch (e) {
        setShowCardInfoErrorAlert({ type: 'danger', message: e.message });
      }
      setCardInfoIsLoading(false);
    };

    fetchData();
  }, [setShowCardInfoErrorAlert]);

  const toggleGetPinModal = e => {
    e.preventDefault();
    setShowGetPinModal(!showGetPinModal);
  };

  const toggleSetPinModal = () => {
    setShowGetPinModal(!showGetPinModal);
    setShowSetPinModal(!showSetPinModal);
  };

  const fetchSensitive = async e => {
    e.preventDefault();
    setCardInfoIsLoading(true);
    try {
      // First fetch one-time token for sensitive info
      const tokenRes = await post(
        `/api/v1/cardholder_portal/cardholder/accounts/${account.id}/sensitive_data`,
        { last_four_digits: account.cards[0].last_four_digits }
      );
      const {
        data: { token },
      } = tokenRes;
      // Exchange token for sensitive info
      const { data: sensitiveCardInfo } = await post(
        '/api/v1/cardholder_portal/cardholder/accounts/sensitive_data',
        { token }
      );
      const formattedSensitiveCardInfo = {
        cardNumber: sensitiveCardInfo.card_number,
        cvv: sensitiveCardInfo.cvv,
        expiryDate: formatExpiryDate(sensitiveCardInfo.expiry_date),
      };
      setCardInfo(formattedSensitiveCardInfo);
      setShowCardInfoErrorAlert(null);
    } catch (e) {
      setShowCardInfoErrorAlert({ type: 'danger', message: e.message });
    }
    setCardInfoIsLoading(false);
  };

  const fetchGalileoPinForm = async e => {
    e.preventDefault();

    setCardInfoIsLoading(true);
    try {
      // Galileo direct render form allows redirect to another URL upon submit
      const callbackDomain =
        /* Uncomment the two lines of code following this comment to develop direct render in local environment. // uncomment for local pin change
       The following two lines are needed in local because Galileo will return an error webpage, if localhost is passed in as callback_url.
       To circumvent, pass in dev url instead. Localhost check commented out to prevent any possibility of the code running in prod */
        // window.location.hostname === 'localhost'
        //   ? 'https://dev.payaccount.io' :
        window.location.origin;
      // Fetch Galileo's direct render form URL
      const lastFourDigits = cardInfo.cardNumber.slice(-4);
      const {
        data: { url },
      } = await post(
        `/api/v1/cardholder_portal/cardholder/accounts/${account.id}/pin/direct_render`,
        {
          last_four_digits: lastFourDigits,
          callback_url: `${callbackDomain}/profile?set_pin_state=success&account_id=${account.id}&last_four_digits=${lastFourDigits}`,
          failure_callback_url: `${callbackDomain}/profile?set_pin_state=failure&account_id=${account.id}&last_four_digits=${lastFourDigits}`,
        }
      );
      // Redirect to direct render form
      window.location.href = url;
    } catch (e) {
      setShowCardInfoErrorAlert({ type: 'danger', message: e.message });
      setCardInfoIsLoading(false);
    }
    // If setCardInfoIsLoading to false here, redirect will take place ~0.5 second after the loader disappears
  };

  const page = 'profile';
  const isVirtualProgram = programConfigs
    ? programConfigs.program_type.includes('virtual')
    : false;
  const allowSensitiveDataToken =
    programConfigs && programConfigs.allow_sensitive_data_token;
  const isNotGalileo =
    programProcessor && programProcessor.processor_name !== 'galileo';
  const isGalileo =
    programProcessor && programProcessor.processor_name === 'galileo';
  const CardSensitiveDetailsColumn = [
    {
      accessor: 'cardNumber',
      header: intl.messages['profile-card-number-text'],
    },
    {
      accessor: 'cvv',
      header: intl.messages['profile-card-cvv-text'],
    },
    {
      accessor: 'expiryDate',
      header: intl.messages['profile-card-expiry-text'],
    },
  ];
  const isPinViewEnabled = 
    programConfigs && programConfigs.pin_view_enabled;
  const isPinSetEnabled = 
    programConfigs && programConfigs.pin_set_enabled;

  const isHRBClient = isHRB();

  const CardInfoLoader = () => (
    <LoaderContainer>
      <Loader />
      <Text
        text={profileEn[`${page}-loader-text`]}
        textFor="loader"
        page={page}
      />
    </LoaderContainer>
  );

  const CardInfoContent = () => (
    <>
      <CardInfoGroup>
        {showCardInfoErrorAlert && (
          <Alert
            alert={showCardInfoErrorAlert.message}
            alertFor={showCardInfoErrorAlert.message}
            page={page}
            type={showCardInfoErrorAlert.type}
          />
        )}
        <Details
          columns={CardSensitiveDetailsColumn}
          data={cardInfo}
          defaultValue="--"
        />
      </CardInfoGroup>
      {showGetPinModal && isNotGalileo && (
        <GetPinModal
          currentAccount={currentAccount}
          toggleModal={toggleGetPinModal}
          toggleSetPinModal={toggleSetPinModal}
          pin={pin}
          setPin={setPin}
          alert={pinModalAlert}
          setAlert={setPinModalAlert}
          initialPinFetch={initialPinFetch}
        />
      )}
      {showGetPinModal && isGalileo && (
        <GetGalileoPinModal
          currentAccount={currentAccount}
          toggleModal={toggleGetPinModal}
        />
      )}
      {showSetPinModal && (
        <SetPinModal
          pin={pin}
          setPin={setPin}
          currentAccount={currentAccount}
          toggleModal={toggleSetPinModal}
          alert={pinModalAlert}
          setAlert={setPinModalAlert}
        />
      )}
    </>
  );

  return (
    <>
      <TitleContainer>
        <Title
          title={profileEn['profile-card-info-title']}
          titleFor="card-info"
          page={page}
        />
        {!cardInfoIsLoading && (
          <ButtonGroup>
            {isPinViewEnabled && (
              <Button
                buttonText={profileEn[`${page}-view-pin-button`]}
                page={page}
                type="secondary"
                buttonFor="view-pin"
                onClick={e => toggleGetPinModal(e)}
              />
            )}
            {/* Currently only galileo allows setting pin from API through direct render forms */}
            {isGalileo && isPinSetEnabled && (
              <Button
                buttonText={profileEn[`${page}-set-pin-button`]}
                page={page}
                type="secondary"
                buttonFor="set-pin"
                onClick={e => fetchGalileoPinForm(e)}
              />
            )}
            {(isHRBClient || isVirtualProgram) && allowSensitiveDataToken && (
              <Button
                buttonText={
                  profileEn['profile-show-sensitive-card-info-button']
                }
                page="profile"
                type="secondary"
                buttonFor="show-sensitive-card-info"
                onClick={fetchSensitive}
              />
            )}
          </ButtonGroup>
        )}
      </TitleContainer>

      {cardInfoIsLoading ? <CardInfoLoader /> : <CardInfoContent />}
    </>
  );
};

CardInfo.propTypes = {
  currentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cards: PropTypes.array.isRequired,
  }),
  programProcessor: PropTypes.shape({
    processor_name: PropTypes.string.isRequired,
  }),
  programConfigs: PropTypes.shape({
    program_type: PropTypes.string.isRequired,
    allow_sensitive_data_token: PropTypes.bool.isRequired,
    pin_view_enabled: PropTypes.bool.isRequired,
    pin_set_enabled: PropTypes.bool.isRequired,
  }),
};

CardInfo.defaultProps = {
  currentAccount: null,
  programProcessor: null,
  programConfigs: null,
};
export default CardInfo;