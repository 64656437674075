// Issuer Statements
// Used in Card Issuing Applications

export const issuerStatementEn = {
  'issuer-statement-p1-text':
    'In Canada, Mastercard prepaid card is issued by Peoples Trust Company under license from Mastercard International Incorporated. Mastercard is a registered trademark, and the circle\'s design is a trademark of Mastercard International Incorporated.',
  'issuer-statement-p2-text':
    'In Canada, Visa prepaid card issued by Equitable Bank pursuant to a license by Visa Int. *Trademark of Visa Int, used under license by Equitable Bank.',
  'issuer-statement-p3-text':
    'In the United States, Visa prepaid card is issued by Sunrise Banks N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Visa logo is a registered trademark of Visa International.'
};

export const issuerStatementFr = {
  'issuer-statement-p1-text':
    'Au Canada, la carte prépayée Mastercard est émise par Peoples Trust Company sous licence de Mastercard International Incorporated. Mastercard est une marque déposée et le design du cercle est une marque de Mastercard International Incorporated.',
  'issuer-statement-p2-text':
    'Au Canada, la carte prépayée Visa émise par la Banque Équitable en vertu d\'une licence de Visa Int. *Marque de commerce de Visa Int, utilisée sous licence par la Banque Équitable.',
  'issuer-statement-p3-text': 
    'Aux États-Unis, la carte prépayée Visa est émise par Sunrise Banks N.A., membre de la FDIC, en vertu d’une licence accordée par Visa U.S.A. Inc. Le logo Visa est une marque déposée de Visa International.'
};