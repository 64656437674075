import { setToken, getToken } from 'shared-components/src/utils/auth';

export const refresh = async () => {
  const { token } = getToken();

  return fetch('/api/v1/cardholder_portal/refresh', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ token }),
  })
    .then(res => {
      if (!res.ok) throw new Error('refresh-failed-error');
      return res.json();
    })
    .then(({ exp, token }) => {
      setToken(token, exp);
    });
};

export const login = async (username, password) => {
  return fetch('/api/v1/cardholder_portal/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  })
    .then(res => {
      if (!res.ok) throw new Error('login-failed-error');
      return res.json();
    })
    .then(({ exp, token }) => {
      setToken(token, exp);
      window.location.pathname = '/';
    });
};

export const forgotPassword = async (payload, setEmailSent) => {
  return fetch('/api/v1/cardholder_portal/forgot_password', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(res => res.json().then(body => {
    if (!res.ok) {
      setEmailSent(false);
      throw new Error(body.error.message || 'An unexpected error occurred');
    } else {
      return setEmailSent(true);
    }
  }));
};

export const resetPassword = async payload => {
  return fetch('/api/v1/cardholder_portal/reset_password', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .then(body => {
      if (body.error) throw new Error(body.error.message);
      return body;
    });
};

export const validateToken = async pwt => {
  return fetch(`/api/v1/cardholder_portal/reset_password?token=${pwt}`)
    .then(res => res.json())
    .then(body => {
      if (body.error) throw new Error(body.error.message);
      return body;
    });
};