import React from 'react';
import { Switch } from 'react-router-dom';
// Import Global Components
import { isHRB } from 'shared-components/src/utils/whitelabel';
import Route from '../components/Route';
// Import Layouts
import NotFound from '../layouts/NotFound';
import Private from './Private';
// Import Local Components
import Header from './Auth/components/Header';
import Login from './Auth/routes/Login';
import Activate from './Auth/routes/Activate';
// import Register from "./layouts/Register";
import Register from './Auth/routes/Register';
import ForgotPassword from './Auth/routes/ForgotPassword';
import ResetPassword from './Auth/routes/ResetPassword';
import CardProxyPage from './Auth/routes/CardholderAgreement';
// Import Layouts
import Body from '../layouts/Body';
import Shell from '../layouts/Shell';
import Footer from '../layouts/Footer';
import { Base } from './Auth/styles';
// Import redirect device component
import RedirectToDevicePortal from './RedirectToDevicePortal';

export default props => {
  const isHRBClient = isHRB();

  return (
    <Shell>
      <Switch>
        <Route
          exact
          path="/cardholder-agreement"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <CardProxyPage {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        <Route
          exact
          path="/login"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <Login {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        <Route
          exact
          path="/activate"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <Activate {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        <Route
          exact
          path="/register"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <Register {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        <Route
          exact
          path="/forgot-password"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <ForgotPassword {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        <Route
          exact
          path="/reset-password"
          render={routeProps => {
            return (
              <Base>
                <Header {...routeProps} {...props} />
                <Body>
                  <ResetPassword {...routeProps} />
                  <Footer />
                </Body>
              </Base>
            );
          }}
        />
        {
          isHRBClient && (
            <Route
              exact
              path="/redirect-to-device-portal"
              render={routeProps => {
                return (
                  <Base>
                    <Header {...routeProps} {...props} />
                    <Body>
                      <RedirectToDevicePortal {...routeProps} />
                      <Footer />
                    </Body>
                  </Base>
                );
              }}
            />
          )
        }
        <Route
          priv
          render={routeProps => {
            return <Private {...routeProps} {...props} />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </Shell>
  );
};
