import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const RedirectToDevicePortal = ({ history }) => {

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('android')) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.sferion.hrbadvantagecard';
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      window.location.href =
        'https://apps.apple.com/us/app/advantage-app/id6736556268';
    } else {
      history.push('/login');
    }
  }, []);

  return (
    <div>
      <p>Redirecting you to the appropriate store...</p>
    </div>
  );
};

RedirectToDevicePortal.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default RedirectToDevicePortal;