import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Input } from 'shared-components';
// Import Global Layouts
import InputGroup from '../../../layouts/InputGroup';
// Import Local Layouts
import AuthLayout from '../layouts/Auth';
import { AuthForm } from '../layouts/InnerAuth';
// Import Utils
import { forgotPassword } from '../../../utils/auth';

const ForgotPassword = ({ history }) => {
  const intl = useIntl();
  const page = 'forgot-password1';

  // Set States
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [username, setUsername] = useState('');

  const handleResetPasswordRequest = async () => {
    try {
      setLoading(true);
      setFormError(null);
      await forgotPassword({ username }, setEmailSent);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFormError(error.message);
    }
  };

  // Set Handlers
  // eslint-disable-next-line consistent-return
  const handleSubmit = async e => {
    e.preventDefault();
    if (username === '') return setFormError('missing-username-error');

    await handleResetPasswordRequest();
  };

  const handleKeyUp = async e => {
    if (e.keyCode === 13) await handleResetPasswordRequest();
  };


  return (
    <AuthLayout
      page={page}
      heading={intl.messages[`${page}-heading`]}
      subHeaderOne={intl.messages[`${page}-subheader1-text`]}
      error={formError && !emailSent ? formError : null}
      success={emailSent && intl.messages[`${page}-email-sent-text`]}
    >
      <AuthForm
        page={page}
        onSubmit={handleSubmit}
        onKeyUp={handleKeyUp}
        secondaryButtonText={intl.messages[`${page}-secondary-button`]}
        secondaryButtonFunc={() => {
          setFormError(null);
          setEmailSent(false);
          return history.push('/login');
        }}
        loading={loading}
      >
        <InputGroup
          label={intl.messages['auth-username-label']}
          labelFor="username"
          page="auth"
          required
        >
          <Input
            onChange={e => setUsername(e.target.value)}
            value={username}
            minLength={3}
            maxLength={30}
            required
          />
        </InputGroup>
      </AuthForm>
    </AuthLayout>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ForgotPassword;
