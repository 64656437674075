import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Alert } from 'shared-components';
// Import Global Components
import Text from '../../../../components/Text';
// Import Local Components
import Heading from '../../components/Heading';
// Import Component Styles
import {
  AuthWrapper,
  AuthContainer,
  Auth,
  Card,
  HeadingContainer,
} from './styles';

const AuthLayout = ({
  page,
  heading,
  subHeaderOne,
  subHeaderTwo,
  error,
  success,
  children,
}) => {
  // Create instance of intl
  const intl = useIntl();
  // See https://developer.mozilla.org/en-US/docs/Web/API/Location/origin for details on window.origin
  // In general, for URL using the http/https, origin returns `${protocol}://${domain}:${port}`
  // For instance:
  // if current url is https://whiteLabelSubDomain.staging.payaccount.io/register
  // origin === 'https://whiteLabelSubDomain.staging.payaccount.io'
  const { origin } = window;

  const handleCardholderAgreementClick = e => {
    e.preventDefault();
    const newTab = window.open(`${origin}/cardholder-agreement`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      window.location.href = `${origin}/cardholder-agreement`;
    }
  };

  // Determine if the current page is "forgot-password1"
  const isForgotPasswordPage = page === 'forgot-password1' ? 'success' : 'danger';

  // Determine the alert type based on the current page
  const alertType = error ? 'danger' : isForgotPasswordPage;

  return (
    <AuthWrapper>
      <Auth>
        <Card>
          <AuthContainer>
            {error && (
              <Alert type={alertType}>
                <FormattedMessage
                  id={error}
                  description="Alert message"
                  defaultMessage={intl.messages[error]}
                />
              </Alert>
            )}
            {success && (
              <Alert type={alertType}>
                <FormattedMessage
                  id={success}
                  description="Alert success"
                  defaultMessage={intl.messages[success]}
                />
              </Alert>
            )}
            <HeadingContainer>
              <Heading heading={heading} page={page} />
              {subHeaderOne && (
                <Text text={subHeaderOne} textFor="subheader1" page={page} />
              )}
              {subHeaderTwo && (
                <Text
                  text={subHeaderTwo}
                  textFor="subheader2"
                  page={page}
                  values={{
                    register: chunks => (
                      <b>
                        <a href={`${origin}/register`}>{chunks}</a>
                      </b>
                    ),
                    activate: chunks => (
                      <b>
                        <a href={`${origin}/activate`}>{chunks}</a>
                      </b>
                    ),
                    login: chunks => (
                      <b>
                        <a href={`${origin}/login`}>{chunks}</a>
                      </b>
                    ),
                    cardholder: chunks => (
                      <b>
                        <a
                          href={`${origin}/cardholder-agreement`}
                          onClick={handleCardholderAgreementClick}
                        >
                          {chunks}
                        </a>
                      </b>
                    ),
                  }}
                />
              )}
            </HeadingContainer>
            {children}
          </AuthContainer>
        </Card>
      </Auth>
    </AuthWrapper>
  );
};

AuthLayout.propTypes = {
  page: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeaderOne: PropTypes.string,
  subHeaderTwo: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  children: PropTypes.element.isRequired,
};

AuthLayout.defaultProps = {
  error: null,
  success: null,
  subHeaderOne: null,
  subHeaderTwo: null,
};

export default AuthLayout;

export const BareAuthLayout = ({ children }) => (
  <AuthWrapper>
    <Auth>
      <Card>
        <AuthContainer>{children}</AuthContainer>
      </Card>
    </Auth>
  </AuthWrapper>
);

BareAuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
