import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Loader } from 'shared-components';
import { get, post } from 'shared-components/src/utils/http';
import { useIntl } from 'react-intl';
import {
  formatCurrencyCode,
  formatCurrencyToCountryCode,
} from 'shared-components/src/utils/formatCurrency';
import { ModalFormContainer } from './styles';

const ThreeDSecureModal = ({
  toggleModal,
  onSuccess,
  onError,
  params,
  page,
}) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const iframeContainerRef = useRef(null);

  const is3DSecureInitiated = status => status === 'authenticate_initiated';
  const isAuthenticationSuccess = authenticationStatus =>
    authenticationStatus === 'Y';
  const isAuthenticationChallenged = authenticationStatus =>
    authenticationStatus === 'C';

  const get3DSecureStatus = async id => {
    const statusResponse = await get(
      `/api/v2/cardholder_portal/cardholder/external_transfers/3d-secure/${id}/status`
    );
    return statusResponse.data;
  };

  const createChallengeIframe = (acsURL, creq, id) => {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement('iframe');
      iframe.id = 'threeDSecureChallengeIframe';
      iframe.style.width = '100%';
      iframe.style.height = '400px';
      iframe.style.border = 'none';
      iframeContainerRef.current.appendChild(iframe);

      const iframeContent = `
      <html>
      <body>
          <form name="sendChallenge" action="${acsURL}" method="post">
              <input name="creq" value="${creq}" />
          </form>
      </body>
      <script type="text/javascript">
          setTimeout(function() {
              document.createElement('form').submit.call(document.sendChallenge);
          }, 100);
      </script>
      </html>
    `;

      iframe.srcdoc = iframeContent;

      const checkStatus = async () => {
        try {
          const {
            authentication_status: authenticationStatus,
          } = await get3DSecureStatus(id);
          if (!isAuthenticationChallenged(authenticationStatus)) {
            resolve(authenticationStatus);
          }
        } catch (error) {
          reject(error);
        }
      };

      setTimeout(async () => {
        reject(
          new Error(intl.messages[`${page}-3ds-authenticated-timeout-alert`])
        );
      }, 120000); // Timeout after 2 minutes

      iframe.onload = async () => {
        await checkStatus();
      };

      iframe.onerror = error => {
        reject(error);
      };
    });
  };

  const createInitialIframe = (methodURL, threeDSMethodData) => {
    const iframe = document.createElement('iframe');
    iframe.id = 'threeDSecureIframe';
    iframe.style.display = 'none'; // Ensure the iframe is hidden
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
        <html>
        <body>
            <form name="sendFingerprint" action="${methodURL}" method="post">
                <input name="threeDSMethodData" value="${threeDSMethodData}" />
            </form>
        </body>
        <script type="text/javascript">
            setTimeout(function() {
                document.createElement('form').submit.call(document.sendFingerprint);
            }, 100);
        </script>
        </html>
    `);
    iframeDoc.close();

    return iframe;
  };

  const getParams = async (accountID, amount) => {
    const accountBalanceData = await get(
      '/api/v1/cardholder_portal/cardholder/current_account/balance'
    );
    const currency = formatCurrencyCode(accountBalanceData.data.currency);
    const countryCode = formatCurrencyToCountryCode(
      accountBalanceData.data.currency
    );

    return {
      amount,
      external_account_id: accountID,
      currency,
      country_code: countryCode,
      message_category: '01',
      challenge_indicator: '01',
      challenge_window_size: '01',
      authentication_ind: '01',
      browser: {
        browser_accept_header: 'application/json',
        browser_java_enabled: navigator.javaEnabled(),
        browser_javascript_enabled: true,
        browser_language: navigator.language,
        browser_color_depth: window.screen.colorDepth.toString(),
        browser_tz: new Date().getTimezoneOffset().toString(),
        browser_screen_width: window.screen.width.toString(),
        browser_screen_height: window.screen.height.toString(),
        browser_user_agent: navigator.userAgent,
      },
    };
  };

  const create3DSecure = async params => {
    setLoading(true);
    const threeDParams = await getParams(
      params.external_account_id,
      params.amount
    );
    const response = await post(
      '/api/v2/cardholder_portal/cardholder/external_transfers/3d-secure',
      threeDParams
    );
    const {
      method_url: methodURL,
      three_ds_method_data: threeDSMethodData,
      id,
      status,
    } = response.data;

    if (!is3DSecureInitiated(status)) {
      throw new Error(intl.messages[`${page}-3ds-authentication-failed-alert`]);
    }

    const iframe = createInitialIframe(methodURL, threeDSMethodData);

    return new Promise((resolve, reject) => {
      iframe.onload = async () => {
        document.body.removeChild(iframe);
        try {
          const statusAfterInitial = await get3DSecureStatus(id);
          const {
            authentication_status: authenticationStatus,
            acs_url: acsURL,
            creq,
          } = statusAfterInitial;
          if (isAuthenticationSuccess(authenticationStatus)) {
            resolve({ id, status: authenticationStatus });
          } else if (isAuthenticationChallenged(authenticationStatus)) {
            const statusAfterChallenge = await createChallengeIframe(
              acsURL,
              creq,
              id
            );
            if (isAuthenticationSuccess(statusAfterChallenge)) {
              resolve({ id, status: statusAfterChallenge });
            } else {
              reject(
                new Error(
                  intl.messages[`${page}-3ds-authentication-failed-alert`]
                )
              );
            }
          } else {
            reject(
              new Error(
                intl.messages[`${page}-3ds-authentication-failed-alert`]
              )
            );
          }
        } catch (e) {
          reject(e);
        }
      };
    });
  };

  useEffect(() => {
    const execute3DSecure = async () => {
      try {
        const result = await create3DSecure(params);
        onSuccess({ ...params, three_d_secure_id: result.id });
        toggleModal(false);
      } catch (error) {
        onError(error);
      }
    };
    execute3DSecure();
  }, []);

  return (
    <Modal>
      <ModalFormContainer ref={iframeContainerRef}>
        <h2>{intl.messages[`${page}-3d-secure-heading`]}</h2>
        <h4>{intl.messages[`${page}-3d-secure-sub-heading`]}</h4>
        {loading ? <Loader /> : null}
      </ModalFormContainer>
    </Modal>
  );
};

ThreeDSecureModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  params: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    transfer_type: PropTypes.string.isRequired,
    external_account_id: PropTypes.number.isRequired,
  }).isRequired,
  page: PropTypes.string.isRequired,
};

export default ThreeDSecureModal;
